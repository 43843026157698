import React, { Component } from "react";
import {
    Button
} from "@material-ui/core";
class Developing extends Component {
    render() {
        return (
            <div className="flex flex-center flex-middle w-100 h-100vh">
                <div className="flex flex-column flex-center flex-middle" style={{ maxWidth: "320px" }}>
                    Tính năng đang phát triển
                </div>
            </div>
        );
    }
}

export default Developing;
